<template>
  <td
    :data-id="itemProp.id"
    class="fixed-column-end nth-actions"
    :class="{
      'zq--active-fixed-column': activeButton[itemProp.id],
      'column-shadow-end': isTableScrollbarProp
    }"
  >
    <div class="zq--table-actions-wrap"
       :class="{'justify-center': isTableScrollbarProp}"
    >
      <!--  SUB ACTIONS    -->
      <slot name="sub-actions"></slot>
      <!--   MAIN ACTIONS   -->
      <CDropdown
        :show.sync="activeButton[itemProp.id]"
        inNav
        class="c-header-nav-items custom-dropbox position-relative"
        placement="left"
        add-menu-classes="p-0"
      >
        <template #toggler>
          <CButton
            :class="
              activeButton[itemProp.id]
                ? 'zq--table-actions--single__rs zq--table-actions--single'
                : 'zq--table-actions--single'
            "
          >
            <ClIcon
              cilIcon="cil-options"
              iconTypeProp="cil"
              imgIcon="actions-more"
            />
          </CButton>
        </template>
        <slot name="actions" v-if="!actions.length"></slot>
        <CDropdownItem
          v-else-if="actions.length"
          v-for="action in actions"
          :key="action.handler"
          class="p-0"
        >
          <CButton
            pill
            color="dark"
            class="action-custom-button"
            :class="{ 'action-custom-button--stroked': action.stroked }"
            variant="ghost"
            @click="actionEvent(action.handler, itemProp)"
            :disabled="action.disabled"
          >
            {{ action.title }}
          </CButton>
        </CDropdownItem>
      </CDropdown>
    </div>
  </td>
</template>

<script>
import ClIcon from "@/shared/UI/ClIcon";

export default {
  name: "ClDropdown",
  components: {
    ClIcon,
  },
  props: {
    itemProp: Object,
    actionsProp: Array,
    itemMimeType: String,
    isTableScrollbarProp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeButton: {},
      actions: [],
      activeToggleId: null,
    };
  },
  created() {
    this.actions = this.actionsProp ? this.actionsProp : [];
    if (this.itemProp.constraints && this.itemProp.constraints.includes('isSystem')) {
      this.actions = this.actionsProp.filter(action => action.title !== 'Delete' && action.title !== 'Edit')
    }

    if (this.itemMimeType !== 'text/html') {
      this.actions = this.actionsProp.filter(action => action.title !== 'Page Builder')
    }
  },
  methods: {
    actionEvent(action, item) {
      this.$emit("choice", action, item);
    },
  },
};
</script>

<style lang="scss">
 .nth-actions { min-width: 75px !important; }
 .action-custom-button--stroked {
   text-decoration: line-through;
 }
</style>