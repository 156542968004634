<template>
  <div class="content">
    <div class="d-flex justify-content-end mb-3 align-items-center">
      <!--   reload   -->
      <CButton
        size="sm"
        class="header-icon-buttons mr-3"
        @click="handleReload"
      >
        <CIcon name="cil-reload" />
      </CButton>
      <ClFilter :isFlag="isFlag" @toggleFilter="toggleFilter"/>
      <CogFields
        :originalFields="originalFields"
        :fields="fields"
        @updateFields="updateFields"
        @clearFilter="clearFilter"
      />

      <CDropdown
        inNav
        class="c-header-nav-items action-button-groups mr-2 custom-dropbox"
        add-menu-classes="p-0"
        v-if="selectedFiles.length"
      >
        <template #toggler>
          <CButton
            class="zq--custom-button"
            variant="outline"
            :color="'dark'"
          >{{ $t('buttons.actions') }}
            <i
              v-if="theme === 'main'"
              class="fa fa-angle-down font-weight-bold"></i>
            <i v-else class="fa fa-caret-down"></i>
          </CButton>
        </template>
        <CDropdownItem class="p-0 actions-dropdown-item">
          <CButton
            pill
            :color="'dark'"
            class="action-custom-button"
            variant="ghost"
            @click="deleteFiles"
          >
            {{ $t('buttons.deleteFiles') }}
          </CButton>
        </CDropdownItem>
      </CDropdown>

      <CButton
        variant="outline"
        color="dark"
        class="mr-2 upload-csv zq--custom-button"
        @click="showCreateFolderModal"
      >
        {{ texts.previewPage.tabFiles.createFolder }}
      </CButton>
      <CButton
        variant="outline"
        color="dark"
        class="mr-2 upload-csv zq--custom-button"
        @click="handleFileUpload"
      >
        {{ texts.previewPage.tabFiles.noContentBtn }}
      </CButton>
      <input
        type="file"
        id="file"
        ref="file"
        multiple
        style="display: none"
        @change="filesChange($event.target.files)"
      />
    </div>

    <!--  Table  -->
    <CRow class="mr-0">
      <CCol col="12">
        <CCard class="zq-card-table">
          <CCardBody>
            <CDataTable
              id="repositoryFilesTable"
              ref="repositoryFilesTable"
              class="zq--table"
              :items="fileObjects"
              :fields="tableFields"
              :items-per-page="itemsPerPage"
              :sorter="{ external: true }"
              :responsive="true"
              :border="isDefaultTheme"
              :hover="isDefaultTheme"
              :striped="isDefaultTheme"
              :loading="loading"
              :columnFilter="columnFilter"
              @pagination-change="itemsPerPageSelect"
              @page-change="paginationChange"
              @toggleFilter="showColumnFilter"
            >
              <template #loading>
                <TableLoader/>
              </template>
              <!--            Select All-->
              <template #select-header class="text-center">
                <div class="position-relative zq--table-select-all" v-theme-select-all>
                  <ClCheckbox
                    :checkedProp="toggleFlag"
                    icon="fa-angle-down"
                    @handler="selectAll"
                  />
                </div>
              </template>
              <!--            Select-->
              <template #select="{ item }">
                <td :data-id="item.id" class="text-center select-td fixed-column-start">
                  <ClCheckbox
                    :valueProp="item.id"
                    :checkedProp="selectedFiles.indexOf(item.id) > -1"
                    @handler="checkFile"
                  />
                </td>
              </template>
              <!--       ID       -->
              <template #id="{ item }">
                <td @click="showPreview(item)">
                  <div class="d-flex flex-nowrap">
                    <CIcon
                      v-if="item.mimeType === 'inode/directory'"
                      name="cil-folder-open"
                      class="mr-2"
                    />
                    <CIcon
                      v-else
                      name="cil-file"
                      class="mr-2"
                    />
                    <CLink class="text-nowrap">
                      {{ item.id }}
                    </CLink>
                  </div>
                </td>
              </template>
              <!--    Created      -->
              <template #created="{item}">
                <td>{{ dateFormate(item.created) }}</td>
              </template>
              <template #uri="{item}">
                <td>
                  <span class="link" @click="openInNewTab(item.uri)">{{ item.uri }}</span>
                </td>
              </template>
              <!--      Actions      -->
              <template #actions-header>
                <div v-theme-header-actions></div>
              </template>
              <template #actions-filter>
                <div></div>
              </template>
              <template #actions="{ item }">
                <ClTableActionsDropdown
                  @choice="dropDownChoice"
                  :itemProp="item"
                  :actionsProp="dropDownActions"
                  :isTableScrollbarProp="isTableScrollbar"
                  :itemMimeType="item.mimeType"
                />
              </template>
            </CDataTable>
            <!--     Table Footer       -->
            <TableFooter
              :page="page"
              @updatePagenation="paginationChange"
              :pages="pages"
              :total="resultCount"
              :itemsPerPage="itemsPerPage"
              :disabled="!ready"
              @updateItemPerPage="itemsPerPageSelect"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <Modal
      :modalShow="deleteModal"
      :messageGeneral="messageGeneral"
      :title="deleteTitle"
      @doFunction="deleteSelectedField"
      v-on:toggle-modal="closeModal('delete')"
    />
    <Modal
      :modalShow="createFolderModal"
      :title="'Create Folder'"
      :messageGeneral="'Folder Name'"
      :successBtnLabel="'Create'"
      @doFunction="createFolder"
      @toggle-modal="closeCreateFolderModal"
    >
      <template #body>
        <CInput
          v-model="folderName"
          :placeholder="'Folder Name'"
          :isrequired="true"
          add-input-classes="col-sm-12"
          name="folderName"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TableFooter from '@/components/table/Footer';
import TableLoader from '@/components/table/Loader';
import Modal from '@/shared/UI/Modal';
import { pageConfig } from '@/config';
import { dateFormate } from '@/utils/dateFormate';
import { fileRepositoriesTexts } from '@/config/pageTexts/fileRepositories.json';
import ClTableActionsDropdown from '@/shared/components/ClTableActionsDropdown';
import ClCheckbox from '@/shared/components/formComponents/ClCheckbox';
import { cloneDeep, delay, uniq } from 'lodash';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import CogFields from '@/components/header/CogFields';
import ClFilter from "@/components/header/ClFilter";

export default {
  components: {
    TableFooter,
    TableLoader,
    ClTableActionsDropdown,
    ClCheckbox,
    Modal,
    CogFields,
    ClFilter,
  },
  data() {
    return {
      texts: {
        ...fileRepositoriesTexts,
      },
      dropDownActions: [
        {
          title: this.$t('buttons.preview'),
          handler: 'showPreview',
        },
        {
          title: this.$t('buttons.edit'),
          handler: 'editFile',
        },
        {
          title: this.$t('buttons.pageBuilder'),
          handler: 'goToEditHtml',
        },
        {
          title: this.$t('buttons.download'),
          handler: 'downloadFile',
        },
        {
          title: this.$t('buttons.delete'),
          handler: 'handleDeleteModal',
        },
      ],
      toggleFlag: false,
      deleteModal: false,
      messageGeneral: '',
      deleteTitle: '',
      selectedId: null,
      selectedFiles: [],
      filesData: [],
      isTableScrollbar: false,
      ready: false,
      loading: false,
      createFolderModal: false,
      folderName: null,
      currentFolder: '/',
      page: pageConfig.page,
      itemsPerPage: pageConfig.itemsPerPage,
      sortBy: [
        {
          queryField: 'created',
          order: 'Desc',
        },
      ],
      tableFields: [],
      includeFieldsInTable: ['select', 'id', 'created', 'name', 'mimeType', 'path', 'uri', 'actions'],
      staticTableFields: [],
      file: '',
      localFolder: '/',
      columnFilter: false,
      isFlag: false,
    }
  },
  computed: {
    ...mapGetters('files', [
      'fileObjects',
      'pages',
      'resultCount',
      'success',
      'message',
      'fields',
      'originalFields',
      'sortableFields',
    ]),
    ...mapGetters('fileRepositories', ['fileRepository']),
    ...mapGetters('theme', ['theme']),
    isDefaultTheme() {
      return this.theme === 'default'
    },
    queryParams() {
      return this.$route.query;
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('files', [
      'handleGetFileObjectsByQuery',
      'handleUploadFileObjects',
      'handleDeleteFileObjects',
      'handleDownloadFileObjects',
      'handleCreateFileObject',
      'handleGetFileObjects_item',
      'handleFields',
      'handleClearFilter',
      'handleGetBreadcrumbs',
    ]),
    async handleReload() {
      await this.handleGetFileObjectsByQuery(this.query);
    },
    async initialize() {
      this.messageGeneral = this.texts.deleteMessage;
      this.deleteTitle = this.texts.deleteTitle;

      this.query = {
        idArray: [],
        queryRequest: {
          must: [
            {
              queryField: 'repositoryId',
              queryValues: [this.$route.params.id],
            },
            {
              queryField: 'parentFolderPath',
              queryValues: ['/'],
            },
          ],
          sortBy: [
            {
              queryField: 'isDirectory',
              order: 'Desc'
            },
            {
              queryField: 'created',
              order: 'Desc'
            }
          ],
          skip: (this.page - 1) * this.itemsPerPage,
          limit: this.itemsPerPage
        }
      };

      if (!this.$route.query.item) {
        this.loading = true;

        await this.handleGetFileObjectsByQuery(this.query);

        this.fields.forEach(field => {
          if (this.includeFieldsInTable.includes(field)) {
            this.tableFields.push({
              key: field,
              sorter: this.sortableFields.includes(field)
            })
          }
        });

        this.staticTableFields = cloneDeep(this.tableFields);

        this.ready = true;
        this.loading = false;
      }
    },
    openInNewTab(url) {
      window.open(url, '_blank');
    },
    handleFileUpload() {
      this.$refs.file.click();
    },
    showCreateFolderModal() {
      this.createFolderModal = true;
    },
    closeCreateFolderModal() {
      this.createFolderModal = false;
      this.folderName = null;
    },
    async createFolder() {
      if (!this.folderName) return;

      this.ready = false;
      this.loading = true;

      await this.handleCreateFileObject({
        createFileObjectRequest: {
          customFields: null,
          tags: null,
          metadata: null,
          repositoryId: this.$route.params.id,
          name: this.folderName,
          mimeType: 'inode/directory',
          parentFolderPath: this.currentFolder,
          constraints: null
        }
      });

      this.createFolderModal = false;

      delay(async () => {
        await this.handleGetFileObjectsByQuery(this.query);
        this.loading = false;
      }, 2000);

      this.folderName = null;
    },
    async filesChange(fileList) {
      if (!fileList.length) return;

      const filesList = Array.from(fileList)

      const parentFolderPath = this.$route.query.localFolderPath
          ? this.$route.query.localFolderPath
          : this.currentFolder;

      const payload = {
        filesArray: filesList,
        parentFolderPath: parentFolderPath,
        repositoryId: this.$route.params.id,
        tagsArray: 'files',
      };

      this.loading = true;
      await this.handleUploadFileObjects(payload);
      delay(async () => {
        await this.handleGetFileObjectsByQuery(this.query);
        this.loading = false;
      }, 2000);
    },
    showColumnFilter(val) {
      this.columnFilter = val;
      if (!val) {
        this.formatFilter();
      }
    },
    checkFile({event}) {
      const index = this.selectedFiles.indexOf(event.target.value);

      if (index > -1) {
        this.selectedFiles.splice(index, 1);
      } else {
        this.selectedFiles.push(event.target.value);
      }
    },
    selectAll() {
      const files = cloneDeep(this.fileObjects);

      this.toggleFlag = !this.toggleFlag;
      if (this.toggleFlag) {
        files.forEach(item => this.selectedFiles.push(item.id));
        this.filesData = [...this.filesData, ...files]
      } else {
        this.selectedFiles = []
      }
    },
    dropDownChoice(action, item) {
      if (this[action]) {
        this[action](item)
      }
    },
    dateFormate(val) {
      return dateFormate(val);
    },
    setCurrentPage(e) {
      this.$emit('setCurrentPage', {page: e});
    },
    //  TABLES
    itemsPerPageSelect(val) {
      this.itemsPerPage = val;
      this.page = 1;
      this.handleGetFiles({
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      });
    },
    paginationChange(val) {
      this.page = val;

      this.handleGetFileObjectsByQuery({
        idArray: [],
        queryRequest: {
          must: [
            {
              queryField: 'repositoryId',
              queryValues: [this.$route.params.id],
            },
            {
              queryField: 'parentFolderPath',
              queryValues: [this.currentFolder],
            },
          ],
          sortBy: [
            {
              queryField: 'isDirectory',
              order: 'Desc'
            },
            {
              queryField: 'created',
              order: 'Desc'
            }
          ],
          skip: (val - 1) * this.itemsPerPage,
          limit: this.itemsPerPage
        }
      });
    },
    async showPreview(item) {
      if (item.mimeType === 'inode/directory') {
        this.localFolder = item.path.replace('/' + this.fileRepository.name, '')

        this.query.queryRequest.must = [
          {
            queryField: 'repositoryId',
            queryValues: [this.$route.params.id],
          },
          {
            queryField: 'parentFolderPath',
            queryValues: [this.localFolder],
          }
        ];
        await this.$router.push({
          path: this.$route.path,
          query: {
            item: item.id,
          },
        });

        this.currentFolder = item.parentFolderPath + '/' + item.name;

        routerBreadcrumbs(
          this.$router.currentRoute,
          {
            id: this.fileRepository.id,
            name: this.fileRepository.name,
            folderName: item.name,
            folderId: item.id,
          }
        );
      } else {
        await this.$router.push({
          name: 'PreviewFile',
          params: {
            fileId: item.id,
            fileName: item.name,
            folderName: item.parentFolderPath.replace('/', ''),
            repositoryName: this.fileRepository.name,
            id: this.fileRepository.id,
          },
          query: {
            innerFolders: this.$router.currentRoute.query.innerFolders
          }
        });
      }
    },
    editFile(item) {
      this.$router.push({
        name: 'EditFile',
        params: {
          fileId: item.id,
          fileName: item.name,
        },
        query: {
          innerFolders: this.$router.currentRoute.query.innerFolders
        }
      })
    },
    async downloadFile(item) {
      let fileObject = await this.handleDownloadFileObjects({path: item.path});

      const blob = new Blob([fileObject], {type : item.mimeType});
      const link = document.createElement('a');

      link.href = URL.createObjectURL(blob);
      link.download = item.name;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    handleDeleteModal(item) {
      this.deleteModal = true;
      this.selectedId = [item.id];
      this.messageGeneral = this.texts.deleteMessage;
    },
    async deleteSelectedField() {
      this.deleteModal = false;
      this.loading = true;
      await  this.handleDeleteFileObjects({idArray: [this.selectedId]})
      this.selectedRecords = [];
      this.toggleFlag = false;
      delay(async () => {
        this.tableData = cloneDeep([]);
        this.multiFields = [];
        await this.handleGetFileObjectsByQuery(this.query);
        this.loading = false;
      }, 2000);
    },
    deleteFiles() {
      const selected = uniq(this.selectedFiles).length;
      const item = selected <= 1 ? 'reward' : 'rewards';
      this.messageGeneral = `You are about to delete ${selected} ${item}. Are you sure?`
      this.deleteModal = true;
      this.selectedId = cloneDeep(this.selectedFiles);
    },
    updateFields(val) {
      this.handleFields(val);
      const idx = this.tableFields.findIndex(f => f.key === val);
      if (idx !== -1) {
        this.tableFields.splice(idx, 1);
      } else {
        const staticIdx = this.staticTableFields.findIndex(f => f.key === val);
        if (staticIdx !== -1) {
          this.tableFields.splice(staticIdx, 0, {key: val, sorter: this.sortableFields.includes(val)});
        }
      }
    },
    clearFilter() {
      this.handleClearFilter();
      this.tableFields = [];

      this.fields.forEach(field => {
        if (this.includeFieldsInTable.includes(field)) {
          this.tableFields.push({
            key: field,
            sorter: this.sortableFields.includes(field)
          })
        }
      });
    },
    toggleFilter(val) {
      this.isFlag = !this.isFlag;
      this.columnFilter = this.isFlag;
      if (!val) {
        this.formatFilter();
      }
    },
    formatFilter() {
      this.$refs.repositoryFilesTable.clean();
      this.page = 1;
      this.handleGetFileObjectsByQuery(this.query);
    },
    goToEditHtml(item) {
      this.$router.push({
        name: 'WebBuilder',
        params: {
          fileId: item.id,
          fileName: item.name,
          id: item.repositoryId,
        },
        query: {
          innerFolders: JSON.stringify(this.$router.currentRoute.query.innerFolders)
        }
      })
    },
  },
  watch: {
    queryParams: {
      deep: true,
      async handler(query) {
        if (!query.item) {
          this.query.queryRequest.must = [
            {
              queryField: 'repositoryId',
              queryValues: [this.$route.params.id],
            },
            {
              queryField: 'parentFolderPath',
              queryValues: ['/'],
            }
          ];
          this.currentFolder = '/';
          this.localFolder = '/';
        } else {
          this.loading = true;
          const folderItem = await this.handleGetFileObjects_item({idArray: [query.item]})
          const folderPath = folderItem[0].path.replace(`/${this.fileRepository.name}`, '')
          const parentFolderPath = folderItem[0].parentFolderPath !== '/' ? folderItem[0].parentFolderPath : ''
          this.currentFolder = parentFolderPath + '/' + folderItem[0].name;
          this.query.queryRequest.must = [
            {
              queryField: 'repositoryId',
              queryValues: [this.$route.params.id],
            },
            {
              queryField: 'parentFolderPath',
              queryValues: [folderPath],
            }
          ];

          const breadcrumbs = await this.handleGetBreadcrumbs({
            repositoryId: this.$route.params.id,
            parentFolderPath: parentFolderPath
          });

          await this.$router.replace({
            path: this.$route.fullPath,
            query: {
              localFolderPath: this.currentFolder,
              innerFolders: JSON.stringify(breadcrumbs)
            },
          }).catch(() => {});

          routerBreadcrumbs(
            this.$router.currentRoute,
            {
              id: this.fileRepository.id,
              name: this.fileRepository.name,
              folderName: folderItem[0].name,
              folderId: folderItem[0].id,
              innerFolders: breadcrumbs.reverse(),
            },
          )
        }

        await this.handleGetFileObjectsByQuery(this.query);

        if (!query.item) {
          routerBreadcrumbs(
            this.$router.currentRoute,
            {
              id: this.fileRepository.id,
              name: this.fileRepository.name,
            },
          )
        }
        this.loading = false;
      }
    },
    fileRepository: {
      async handler(val) {
        this.loading = true;
        if (this.$route.query.item) {
          const folderItem = await this.handleGetFileObjects_item({idArray: [this.$route.query.item]})
          const folderPath = folderItem[0].path.replace(`/${val.name}`, '')
          this.currentFolder = folderItem[0].parentFolderPath + '/' + folderItem[0].name;
          this.query.queryRequest.must = [
            {
              queryField: 'repositoryId',
              queryValues: [this.$route.params.id],
            },
            {
              queryField: 'parentFolderPath',
              queryValues: [folderPath],
            }
          ];
          const innerFolders = this.$router.currentRoute.query.innerFolders && this.$router.currentRoute.query.innerFolders.length
              ? JSON.parse(this.$router.currentRoute.query.innerFolders).reverse()
              : [];

          routerBreadcrumbs(
            this.$router.currentRoute,
            {
              id: val.id,
              name: val.name,
              folderName: folderItem[0].name,
              folderId: folderItem[0].id,
              innerFolders: innerFolders,
            },
          );
        }

        await this.handleGetFileObjectsByQuery(this.query);

        this.tableFields = [];

        this.fields.forEach(field => {
          if (this.includeFieldsInTable.includes(field)) {
            this.tableFields.push({
              key: field,
              sorter: this.sortableFields.includes(field)
            })
          }
        });

        this.staticTableFields = cloneDeep(this.tableFields);

        this.loading = false;
        this.ready = true;
      }
    },
  }
}
</script>

<style lang="scss">
 .content {
   padding: 1.5rem;
 }
</style>
