<template>
  <div class="preview">
    <!--    HEADER    -->
    <div class="zq-page-title-wrapper d-flex">
      <h3 class="zq-page-title">{{ formData.name }}</h3>
      <IconWithTooltip
        class="zq--header-tooltip"
        :text="descriptions.pageTitle"
      />
    </div>
    <CTabs class="zq--tabs">
      <CTab :title="texts.previewPage.tabRepositoryFiles">
        <FileRepositoryFilesTable />
      </CTab>
      <CTab :title="texts.previewPage.tabRepositoryPreview">
        <FileRepositoryDetails />
      </CTab>
    </CTabs>
  </div>
</template>

<script>
import { fileRepositories } from '@/config/descriptions/fileRepositories.json';
import { fileRepositoriesTexts } from '@/config/pageTexts/fileRepositories.json';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import FileRepositoryDetails from '@/generated/ziqni/views/fileRepositories/FileRepositoryDetails'
import FileRepositoryFilesTable from '@/views/ziqni/fileRepositories/FileRepositoryFilesTable';

export default {
  name: 'PreviewFileRepository',
  components: {
    FileRepositoryDetails,
    IconWithTooltip,
    FileRepositoryFilesTable
  },
  data() {
    return {
      descriptions: {
        ...fileRepositories.repos.preview,
      },
      texts: {
        ...fileRepositoriesTexts,
      },
      formData: {
        name: '',
      },
    }
  }
};
</script>

<style lang="scss">
.preview {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
