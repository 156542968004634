<template>
  <!-- Generated using model-view-preview.mustache [ DO NOT EDIT GENERATED FILES DIRECTLY! ] -->
  <div class="fileRepository-detail">
    <div class="form-content content">
      <ActionsForPreview
        v-if="!isActionsDisabled"
        :loading="loading"
        @edit="editFileRepository"
        @delete="openModal"
      />
      <div v-if="ready">
        <CRow>
          <CCol col="12">
            <CCard class="zq--wizard-card" v-if="ready">
              <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
                <div class="d-flex justify-content-between">
                  <strong class="title">{{ texts.previewPage.title }}</strong>
                  <CLink class="card-header-action btn-minimize">
                    <ClCardArrow :is-collapsed="basicIsCollapsed" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="basicIsCollapsed" :duration="400">
                <CCardBody>
                  <ZqFieldsView :texts="texts.previewPage" :formData="formData" :modelFields="modelFields" />
                </CCardBody>
              </CCollapse>
            </CCard>
          </CCol>
        </CRow>
        <ViewRules
          v-if="ruleModels.includes(model)"
          :entityId="entityId"
        />
        <ViewDependantOn
          v-if="formData.dependantOn && Object.keys(formData.dependantOn).length"
          :dependantOnData="formData.dependantOn"
        />
        <ViewScheduling
          v-if="schedulingData"
          :schedulingData="schedulingData"
        />
        <ViewTranslations
          v-if="modelFields.baseFields.translatableFields.length > 0"
          :entityData="formData"
          :entityId="formData.id"
          :translatableFields="modelFields.baseFields.translatableFields"
        />
        <ViewRewards :entityId="entityId" v-if="rewardModels.includes(model)"/>
      </div>
      <PreviewSpiner v-else />
    </div>
    <Modal
      :modalShow="deleteModal"
      :messageGeneral="texts.deleteMessage"
      :title="texts.deleteTitle"
      @doFunction="deleteFileRepository"
      v-on:toggle-modal="deleteModal = false"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import ActionsForPreview from '@/shared/components/ActionsForPreview';
  import CIMetaDataView from '@/shared/components/CIMetaDataView';
  import ZqCardRow from '@/shared/UI/ZqCardRow';
  import ZqFieldsView from '@/shared/components/ZqFieldsView';
  import ClCardArrow from '@/shared/UI/ClCardArrow';
  import { pageConfig } from '@/config';
  import PreviewSpiner from '@/shared/UI/Spiner';
  import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
  import Modal from '@/shared/UI/Modal';
  import { fileRepositoriesTexts } from '@/config/pageTexts/fileRepositories.json';
  import fields from '@/generated/ziqni/store/modules/fileRepositories/fields';
  import ViewTranslations from '@/shared/components/supportModels/translations/ViewTranslations';
  import ViewRules from '@/shared/components/supportModels/rules/ViewRules';
  import ViewDependantOn from '@/shared/components/supportModels/dependantOn/ViewDependantOn';
  import ViewScheduling from '@/shared/components/supportModels/scheduling/ViewScheduling';
  import ViewRewards from '@/shared/components/supportModels/rewards/ViewRewards';
  export default {
    name: 'FileRepositoryDetails',
    components: {
      PreviewSpiner,
      ActionsForPreview,
      ZqFieldsView,
      ZqCardRow,
      Modal,
      CIMetaDataView,
      ClCardArrow,
      ViewTranslations,
      ViewRules,
      ViewDependantOn,
      ViewScheduling,
      ViewRewards,
    },
    data() {
      return {
        model: 'fileRepository',
        entityId: this.$route.params.id,
        formData: {
            id: '',
            spaceName: '',
            created: '',
            customFields: {},
            tags: [],
            metadata: {},
            name: '',
            description: '',
            constraints: [],
            hostingOptions: ''
        },
        texts: {
          ...fileRepositoriesTexts,
        },
        basicIsCollapsed: true,
        inboxIsCollapsed: false,
        deleteModal: false,
        itemsPerPage: pageConfig.itemsPerPage,
        query: {},
        sortBy: pageConfig.sortBy,
        page: pageConfig.page,
        modelFields : {
          ...fields,
        },
        ready: false,
        schedulingData: null,
        rewardModels: ['achievement'],
        ruleModels: ['achievement'],
        isActionsDisabled: false,
      };
    },
    computed: {
      ...mapGetters('fileRepositories', [
        'success',
        'message',
        'fileRepositories',
        'loading',
        'fileRepository'
      ]),
      ...mapGetters('theme', ['theme']),
    },
    created() {
      this.initialize();
    },
    methods: {
      ...mapActions('fileRepositories', [
        'handleGetFileRepositories_item',
        'handleDeleteFileRepositories',
      ]),
      async initialize() {
        await this.handleGetFileRepositories_item([this.$route.params.id],1,0).then((data) => {
          routerBreadcrumbs(this.$router.currentRoute, {
            name: data[0].name,
          });
          if (data[0].scheduling) {
            this.schedulingData = data[0].scheduling;
          }
          if (data[0].constraints.includes('isSystem')) {
            this.isActionsDisabled = true;
          }
          this.ready = true;
        });
      },
      editFileRepository() {
        this.$router.push({
          name: 'EditFileRepository',
          params: {
            id: this.formData.id,
            name: this.formData.name,
          },
        });
      },
      deleteFileRepository() {
        this.deleteModal = false;
        this.handleDeleteFileRepositories({
          idArray: [this.entityId],
          queryData: this.query,
          sortBy: this.sortBy,
          skip: (this.page - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
        }).then(() => this.$router.push({ name: 'FileRepositorys' }).catch((e) => {
            console.log(e.message);
           }));
      },
      openModal() {
        this.deleteModal = true;
      },
    },
    watch: {
      fileRepository: {
        deep: true,
        handler: function (val) {
          this.formData = val;
        },
      },
      fileRepositories: {
        deep: true,
        handler: function () {
          this.$router.push({ path: '/fileRepositories' }).catch((e) => {
            console.log(e.message);
          });
        },
      },
    },
  };
</script>
<style lang="scss">
  fileRepository-detail {
    height: 100%;
    flex: 2;
    .content {
      border-top: 1px solid var(--zq-content-border-color);
    }
    .form-content {
      height: calc(100% - 50px);
      background: var(--zq-sub-bg);
    }
    .lang-custom-button {
      width: 100%;
    }
  }

  .detail-label {
    @media (min-width: 420px) {
      max-width: 220px;
    }
  }

  .overview-label {
    font-style: italic;
    color: darkgray;
  }
</style>
